.about {
    height: 90vh;
}
.about-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10%;
}

.picture {
    height: 420px;
}
.about-info {
    font-size: 24px;
}
.info-name {
    color: #7e57c2;
    font-size: 32px;
    font-weight: 800;
}
.button {
    height: 50px;
    width: 150px;
    background-color: white;
    border-radius: 10px;
    border-color: #7e57c2;
    font-size: 20px;
    font-weight: 800;
    animation: appear 2.5s;
    margin-top: 10px;
    
}
.link {
    text-decoration: none;
    color: #7e57c2;
}
.link:link{
    color: #7e57c2;
}
@keyframes appear {
    0%{
        opacity: 0%;
    }
    10%{
        opacity: 10%;
    }
    20%{
        opacity: 20%;
    }
    30%{
        opacity: 30%;
    }
    50%{
        opacity: 50%;
    }
    100% {
        opacity: 100%;
    }
}

@media only screen and (max-width: 720px) {
    .about-top{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .picture {
        height: 280px;
    }
    .about-info {
        font-size: 17px;
        width: 265px;
    }
    .info-name {
        font-size: 26px;
    }
}