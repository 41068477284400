.contact {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
}
.form-container{
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0 10px 0;
}
.form-info{
    width: 40%;
    font-weight: 400;
    font-size: larger;
}
.form-input-info{
    width: 50%;
    height: 28px;
    border-width: 2px;
    border-color: black;
    padding-left: 5px;
}
.form-input{
    width: 50%;
    height: 100px;
    border-width: 2px;
    border-color: black;
    padding-left: 5px;
    padding-top: 5px;
}
.form {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    background-color: #9b72e1;
    border-radius: 10px;
    height: 500px;
    
    border-color: black;
} 

.section-title {
    font-size: 32px;
    font-weight: 700;
}

.contact-container {
    display: flex;
    flex: 1;
    justify-content: center;
    text-align: center;
}

.contact-form{
    font-size: 18px;
}

.form-button{
    height: 50px;
    width: 150px;
    background-color: #a98fd6;
    border-radius: 10px;
    border-color: black;
    font-size: 20px;
    font-weight: 800;
}