.skill-card{
    width: max-content;
    padding: 8px 16px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 6px;
    border-radius: 8px;
}

.skill-icon {
    font-size: 80px;
    height: 100px;
}

.skill-name{
    font-size: 18px;
}

@media only screen and (max-width: 1024px) {
    .skill-card {
      width: max-content;
      height: 100px;
    }
    .skill-icon {
      font-size: 60px;
    }
}
